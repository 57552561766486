import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './components/errorPages/page-not-found/page-not-found.component';
import { AuthenticationModule } from './authentication/module/authentication.module';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthenticationModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
    cookieName: 'csrftoken',  
    headerName: 'X-CSRFToken',
  }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}