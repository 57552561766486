import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as Base64 from 'crypto-js/enc-base64';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService {
  private authURL = environment.serverIP;
  private heartBeat = 'api/check_auth/';
  private getIP = 'get_ip/';
  roles = [];
  permissionsArray = [];
  public permissions = new BehaviorSubject<any>([]);

  ErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      'X-CSRFToken':document.cookie
    })
  };
  constructor(private http: HttpClient) {}

  HMACGeneration(userKey, secretKey, args): Observable<any> {
    userKey = JSON.stringify(userKey);
    secretKey = JSON.stringify(secretKey);
    return new Observable((observer) => {
      this.getPublicIPAddress().subscribe(
        (data) => {
          let endPointResult: any;
          let response: any = data;
          if (response.status == 'ok') {
            let method = args.method;
            let timestamp = new Date().toISOString();
            let endPoint = args.endPoint;
            if (args.parameters) {
              let data = JSON.stringify(args.parameters);
              endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n${data}`;
            } else {
              endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n`;
            }
            let generatedHMAC = CryptoJS.algo.HMAC.create(
              CryptoJS.algo.SHA256,
              `${JSON.parse(secretKey)}`
            );
            generatedHMAC.update(endPointResult);
            let HMAC = Base64.stringify(generatedHMAC.finalize());
            observer.next({
              status: 'ok',
              HMAC_Sign: HMAC,
              user_key: JSON.parse(userKey),
              timeStamp: timestamp,
            });
            observer.complete();
          } else {
            this.ErrorMessage.next(
              'Something went wrong in authentication, Please try again!'
            );
            observer.next({ status: 'error' });
            observer.complete();
          }
        },
        (err) => {
          this.ErrorMessage.next(
            'Something went wrong in authentication, Please try again!'
          );
          observer.next({ status: 'error', err: err });
          observer.complete();
        }
      );
    });
  }

  loginUser(credentials): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + 'api-token-auth/',
          { username: credentials.userName, password: credentials.password },
          this.httpOptions
        )
        .subscribe(
          (data) => {
            let response: any = data;
            if (response && response.token) {
              observer.next({ status: 'ok', token: response.token });
              observer.complete();
            } else {
              observer.next({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            this.ErrorMessage.next(
              'Service Temporarily Unavailable, please try again'
            );
            observer.next({ status: 'error', err: err });
            observer.complete();
          }
        );
    });
  }

  setHeaders(hmacData) {
    let options;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'jwt ' + JSON.parse(hmacData.token),
      Signature: hmacData.HMAC_Sign,
      Key: hmacData.user_key,
      timestamp: hmacData.timeStamp,
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      'X-CSRFToken':document.cookie
    });
    options = {
      headers: headers,
    };

    return options;
  }

  getFromLocal(item) {
    return sessionStorage.getItem(item);
  }

  getKeys() {
    if (
      sessionStorage.getItem('userKey') &&
      sessionStorage.getItem('secretKey')
    ) {
      return {
        userKey: sessionStorage.getItem('userKey'),
        secretKey: sessionStorage.getItem('secretKey'),
      };
    } else {
      return;
    }
  }

  checkAuth(userkey, secretkey, token): Observable<any> {
    return new Observable((observer) => {
      this.HMACGeneration(userkey, secretkey, {
        endPoint: '/' + this.heartBeat,
        method: 'GET',
      }).subscribe((res) => {
        let headerOptions = {
          HMAC_Sign: res.HMAC_Sign,
          user_key: res.user_key,
          timeStamp: res.timeStamp,
          token: token,
        };

        this.http
          .get(this.authURL + this.heartBeat, this.setHeaders(headerOptions))
          .subscribe(
            (res) => {
              let response: any = res;
              if (response && response.data) {
                observer.next({
                  status: 'ok',
                  userID: response.data.used_id,
                  user_type: response.data.user_type,
                  user_FN:response.data.first_name.toUpperCase()[0],
                  user_LN:response.data.last_name.toUpperCase()[0]
                });
                observer.complete();
              }
            },
            (err) => {
              if (err.error) {
                this.ErrorMessage.next(
                  'Invalid secret key or user key. Try again!'
                );
                observer.next({ status: 'error' });
                observer.complete();
              }
            }
          );
      });
    });
  }

  getPublicIPAddress(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(this.authURL + this.getIP,this.httpOptions).subscribe(
        (data) => {
          let response: any = data;
          if (response) {
            observer.next({ status: 'ok', ip: response });
            observer.complete();
          } else {
            observer.next({ status: 'error' });
            observer.complete();
          }
        },
        (err) => {
          this.ErrorMessage.next('Internal Server Error');
          observer.next({ status: 'error', code: 500 });
          observer.complete();
        }
      );
    });
  }
}
