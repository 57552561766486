import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;
  @Input() ThemeStatus: boolean;
  userType = "";
  userID = "";
  subscriptions: Subscription[] = [];
  groups = [];
  firstLetter = '';
  lastLetter = '';
  constructor(public _router:Router) {
    this.userType = localStorage.getItem('userType');
    this.userID = sessionStorage.getItem('userID');
   }

  ngOnInit(): void {
  }

  LogOut(){
    const user = sessionStorage.getItem('currentUserTrellis');
    if (user) {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = `${encodeURIComponent('csrftoken')}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      this._router.navigate(['/login']);
    }
  }

  GotoLink(route){
    this._router.navigate(['pages/' + route]);
  }

}
