import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
    currentRouteURL:any;
    event$:any;
    constructor(private _router: Router) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if(sessionStorage.getItem('currentUserTrellis') && sessionStorage.getItem('loggedIn')){
            return true;
        }
        else{
            this._router.navigate(['/login']);
            return false;
        }
        
    }
   
}